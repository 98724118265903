import { useInlineMemoizedContentGetter, useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { LinkButton } from 'components/Contentful'
import React, { useState } from 'react'
import { TitleDivider } from 'components/TitleDivider'
import { faqSetSchema } from 'constants/validation-types'
import { ChevronDown, ChevronUp } from 'react-feather'
import { FAQPageJsonLd } from 'next-seo'
import { get as _get } from 'lodash-es'
import { returnContentStringFaqAnswer } from 'utils/shared'
import { FaqAccordion } from 'components/FaqAccordion'
import classNames from 'classnames'
import { Grid } from '@achieve/ascend'
import { Button } from 'components/Button'

function Faqs({ content }) {
  const MemoizedContentGetter = content?.fields
    ? useMemoizedContentGetter
    : useInlineMemoizedContentGetter
  const memoizedContent = MemoizedContentGetter(content, [
    'button',
    'faqLink',
    'faqs',
    'title',
    'show',
    'config',
    'accordionEvent',
    'allEvent',
  ])

  const sectionName = content?.fields?.fieldName || 'faqs'
  const { button, faqLink, faqs, title, show, config, accordionEvent, allEvent } = memoizedContent
  const faqSets = (faqs?.faqSetsCollection?.items ?? faqs).map((faqSet) => {
    const set = {
      ...faqSet,
      ...faqSet?.fields,
    }
    const answer = set?.answer?.json ?? set?.answer
    const question = set?.question?.json ?? set?.question

    return {
      ...set,
      answer,
      question,
    }
  })

  if (!faqSetSchema.isValidSync(faqSets)) {
    console.warn('FaqsSection - INVALID CONTENT RESPONSE', JSON.stringify(memoizedContent))
    return null
  }

  return (
    <FaqsSection
      button={button}
      faqLink={faqLink}
      faqs={faqs}
      title={title}
      show={show}
      config={config}
      accordionEvent={accordionEvent}
      allEvent={allEvent}
      sectionName={sectionName}
      faqSets={faqSets}
    />
  )
}

function FaqsSection({
  button,
  faqLink,
  faqs,
  title,
  show,
  config,
  accordionEvent,
  allEvent,
  questionTextVariant,
  answerTextVariant,
  titleProps,
  fullWidth = false,
  sectionName,
  faqSets,
}) {
  const [selected, setSelected] = useState('set1')
  const [open, setOpen] = useState(false)

  const showByDefault = _get(show, 'textContent.content[0].content[0].value')
  const showDefault = Number(showByDefault) >= 0 ? Number(showByDefault) : 5
  const encryptionKey = faqs?.encryptionKey

  const makeAccordion = (
    articles,
    formConfig,
    miniCalculatorConfig,
    answer,
    question,
    setNumber,
    open,
    index,
    faqId
  ) => {
    return (
      <React.Fragment key={index}>
        <FaqAccordion
          formConfig={formConfig}
          articles={articles}
          answer={answer}
          question={question}
          setNumber={setNumber}
          selected={selected}
          setSelected={setSelected}
          open={open}
          index={index}
          faqId={faqId}
          questionTextVariant={questionTextVariant}
          answerTextVariant={answerTextVariant}
          encryptionKey={encryptionKey}
          miniCalculatorConfig={miniCalculatorConfig}
        />
      </React.Fragment>
    )
  }

  const makeSeoEntity = (answerData, questionData) => {
    const answer = answerData?.content
      ?.map((answerData) => returnContentStringFaqAnswer(answerData))
      ?.join(' ')
    const question = questionData?.content?.[0]?.content[0]?.value
    return {
      questionName: question,
      acceptedAnswerText: answer,
    }
  }

  const mapFaqSets = () => {
    let entities = []
    let faqs = []

    faqSets.map((set, index) => {
      const answer = set?.answer
      const question = set?.question
      const setNumber = set?.fieldName
      const articles = set?.articlesCollection?.items || []
      const formConfig = set?.formConfig || false
      const miniCalculatorConfig = set?.miniCalculatorConfig || false
      const entity = makeSeoEntity(answer, question)
      const accordion = makeAccordion(
        articles,
        formConfig,
        miniCalculatorConfig,
        answer,
        question,
        setNumber,
        open || index + 1 <= showDefault,
        index,
        set?.sys?.id
      )
      entities.push(entity)
      faqs.push(accordion)
    })

    return (
      <>
        <FAQPageJsonLd mainEntity={entities} />
        {faqs}
      </>
    )
  }

  return (
    <Section
      className={classNames(
        'py-8 px-0 lg:py-8 lg:px-0',
        config?.jsonContent?.color & (config?.jsonContent?.color === 'gray')
          ? 'bg-[#f8f9fc]'
          : config?.jsonContent?.color === 'highlight-blue'
          ? 'bg-[#eff5ff]'
          : ''
      )}
      data-testid={`${sectionName}-section`}
      id="faq-section"
    >
      <Grid
        alignItems="center"
        columnSpacing={{ xs: 1, lg: 3 }}
        container
        data-testid="faqs-grid-container"
        justifyContent="center"
      >
        <Grid data-testid="faqs-grid-item-title" item xs={fullWidth ? 12 : 9}>
          <TitleDivider
            title={title}
            data-testid="faqs-section-title-area"
            className={
              'text-left mb-8 [&_div]:data-[page-main-variation="website-tenant-1"]:text-[#008000]'
            }
            disableTitleTag={true}
            classNameTitleText="displayMd lg:displayLg font-medium"
            classNameSubtitleText="bodySm lg:displaySm font-light"
            {...titleProps}
          />
        </Grid>
        <Grid data-testid="faqs-grid-item-accordion" item xs={11} lg={fullWidth ? 12 : 9}>
          {faqSets && mapFaqSets()}
        </Grid>
        {faqSets?.length > showDefault && (
          <Grid data-testid="faqs-grid-item-button" item pt={8} xs={11}>
            <Grid container justifyContent="center">
              <Button
                variant="text"
                onClick={() => setOpen(!open)}
                endIcon={open ? <ChevronUp /> : <ChevronDown />}
                track={{
                  ...{
                    list_name: 'FAQ ACCORDION LESS/MORE',
                    click_id: open ? 'Less' : 'More',
                    click_text: open ? 'Show less' : 'Show more',
                    click_type: 'UI Click',
                    nav_link_section: 'FAQs',
                    click_position: '0',
                    track_event: 'ui_click',
                    event_action: 'ui_click',
                    event_type: 'ui_click',
                  },
                  ...accordionEvent?.jsonContent,
                }}
              >
                {open ? 'Show less' : 'Show more'}
              </Button>
            </Grid>
          </Grid>
        )}
        {faqLink && (
          <Grid data-testid="faqs-grid-item-link-faq" item pt={8} xs={11} className={'pt-8'}>
            <Grid container justifyContent="center">
              <LinkButton
                className={'h-[47px] w-[293px]'}
                content={faqLink}
                data-testid="faqs-link-faq"
                variant="text"
                typographicOptions={{
                  fontWeight: 'bold',
                  'data-testid': 'faqs-link-faq-text',
                }}
                track={{
                  ...{
                    list_name: 'FAQ SEE ALL',
                    click_id: 'See all FAQs',
                    click_text: 'See all FAQs',
                    nav_link_section: 'FAQs',
                    track_event: 'page_nav_click',
                    event_action: 'link_click',
                    event_type: 'link_click',
                  },
                  ...allEvent?.jsonContent,
                }}
              />
            </Grid>
          </Grid>
        )}

        {button && (
          <Grid data-testid="faqs-grid-item-button" item pt={8} xs={11} className={'pt-8'}>
            <Grid container justifyContent="center">
              <LinkButton
                track={{
                  ...{
                    list_name: 'Product Page FAQ CTA',
                    click_id: button?.linkText?.json?.content[0]?.content[0].value,
                    click_url: button?.linkHref,
                    click_type: 'Button Click',
                    click_position: '0',
                    nav_link_section: 'FAQs',
                    track_event: 'internal_campaign_click',
                    event_action: 'button_click',
                    event_type: 'button_click',
                  },
                  ...button?.event,
                }}
                className={'h-[47px] w-[293px]'}
                content={button}
                data-testid="faqs-button"
                typographicOptions={{
                  fontWeight: 'bold',
                  'data-testid': 'faqs-button-text',
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Section>
  )
}
export { Faqs }
export default Faqs
